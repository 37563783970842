import firebase from 'firebase'

export default {
  state: {
    //nearby
    selCat: null,
    showMorePhotos: false,
    preFetchNearby: null,
    getDetails: null,
    detailsLoading: false,
    hideOtherDetails: false,
    details: {},
    showDetailsForPlace_id: null,
    nearbyCards: {}
},
  getters: {
    //nearby  
    selCat: state => state.selCat,
    showMorePhotos: state => state.showMorePhotos,
    preFetchNearby: state => state.preFetchNearby,
    nearbyCards: state => state.nearbyCards,
    hideOtherDetails: state => state.hideOtherDetails,
    showDetailsForPlace_id: state => state.showDetailsForPlace_id,
    //build a categories array from nearbyCards for menu
    categories: state => {
      let categories = []
      let nearbyCardsArray = Object.values(state.nearbyCards)
      nearbyCardsArray.forEach( (item) => {
        let newItem = {}
        newItem.label = item.description
        newItem.category = item.category
        categories.push(newItem) 
      })
      return categories
    },
    getDetails: state => state.getDetails,
    details: state => state.details,
    // TODO: these are probably not used
    // keys: state => Object.values(state.preFetchNearby),
    // detailsLoading: state => state.detailsLoading,

  },
  mutations: {
    setSelCat (state,payload){
      state.selCat = payload
    },
    setShowMorePhotos (state,payload){
      state.showMorePhotos = payload
    },
    setPreFetchNearby (state,payload){
      state.preFetchNearby = payload
    },
    setNearbyCards (state,payload){
      state.nearbyCards = payload
    },
    loadNearbyCards (state,payload){
      state.nearbyCards[payload.keyword].array = payload.array
    },
    setGetDetails (state,payload){
      state.getDetails = payload
    },
    setDetailsLoading (state,payload){
      state.detailsLoading = payload
    },
    setDetails (state,payload){
      state.details = payload
    },
    setHideOtherDetails (state,payload){
      state.hideOtherDetails = payload
    },
    setShowDetailsForPlace_id (state,payload){ 
      // console.log(("setShowDetailsForPlace_id",payload)
      state.showDetailsForPlace_id = payload
    }

  },
  actions: {
    
    getPreFetchNearby (context, payload) {
      // console.log(('getPreFetchNearby', payload)
      let propertyId = context.getters.propertyId
      const propertiesRef = firebase.firestore().collection('properties')
      const nearbyRef = propertiesRef.doc(propertyId).collection('nearby')
      nearbyRef.get().then( docs => {
        // console.log((docs.size)
        let array = []
        docs.forEach( doc => {
          let obj = {[doc.id]: doc.data()}
          obj = {...obj,keyword:doc.id}

          // console.log(("doc: ",obj)
          array.push(obj)
        })
        context.commit('setPreFetchNearby', {...array})
      } )
    },
    
    subscribeToPrefetchNearby (context, payload) {
      console.log('subscribeToPrefetchNearby', payload)
      let propertyId = context.getters.propertyId
      const propertiesRef = firebase.firestore().collection('properties')
      const nearbyRef = propertiesRef.doc(propertyId).collection('nearby')
      nearbyRef.onSnapshot(snapshot => {
        let array = []
        snapshot.forEach( doc => {
          let obj = {[doc.id]: doc.data()}
          obj = {...obj,keyword:doc.id}
          // console.log(("doc: ",obj)
          array.push(obj)
        })
        context.commit('setPreFetchNearby', {...array})
      })      
    },
    
    //This is the main callable function for getting nearby items
    async getDetails (context, placeId) {
      // console.log(('getDetails', placeId)
      context.commit('setDetailsLoading', true)
      let getDetails = firebase.functions().httpsCallable('getNearbyDetails')
      return await getDetails({place_id: placeId})
      .then(result => {
          let obj = result.data.result
          obj = {...obj,place_id: placeId}
          // console.log(("Details ", obj)
          return obj
      })
    },
  
    async getNearbyPlaceFromText (context, payload) {
      console.log('getNearbyPlaceFromText', payload)
      let ref = firebase.functions().httpsCallable('getNearbyPlaceFromText')
      return await ref( payload )
      .then(result => {
        console.log("result ", result)
        return result
      })
    },
      
    async getNearbyCards(context, payload) {
      let array = []
      const nearbyRef = firebase.firestore().collection('defaults').doc('nearby')
      await nearbyRef.get()
      .then( doc => {
        let nearbyObj = doc.data()
        for (let key in nearbyObj) {
          let newKey = nearbyObj[key].keyword
            nearbyObj[newKey] = nearbyObj[key]  //rename key to keyword
            delete nearbyObj[key]
            nearbyObj[newKey].array = []
        }
        context.commit('setNearbyCards', nearbyObj)
      })   
    },
    
    clearNearbyPrefetched(context, propertyId){
      // console.log(("clearNearbyPrefetched")
      let deletePropertyNearby = firebase.functions().httpsCallable('deletePropertyNearby')
      deletePropertyNearby({propertyId: propertyId})
      .then(result => {
        // console.log(("result", result)
        return result
      })
   },

  },

}