import firebase from 'firebase'
import BackgroundImages from "./backgroundImages.json"

export default {
  state: {
    backgroundImages: BackgroundImages,
  }, 
  getters: {
    backgroundImages: state => state.backgroundImages,
    backgroundColorNamesArray: state => {
      return state.backgroundImages.map( el => el.name)
    },
    temporaryBackground: state => state.temporaryBackground,    
  },
  mutations: {
    setBackgroundImages(state, payload) {
      state.backgroundImages = payload
    },
    setTemporaryBackground (state, payload){
      state.temporaryBackground = payload
    },

  },
  actions: {
    //not in use yet  
    async getBackgroundImages({commit}) {
      const db = firebase.firestore().collection('defaults').doc('backgroundImages')
      const backgroundImages = []
      const backgroundImagesRef = db.collection('backgroundImages')
      const snapshot = await backgroundImagesRef.get()
      snapshot.forEach(doc => {
        backgroundImages.push(doc.data())
      })
      commit('setBackgroundImages', backgroundImages)
    },
    async addBackgroundImages(context, payload) {
      payload = context.getters.BackgroundImages
      const db = firebase.firestore().collection('defaults')
      const backgroundImagesRef = db.doc('backgroundImages')
      // add payload array to firebase
      
    }
  }
}