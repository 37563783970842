<template>
    <div class="mb-5 mt-n7 wide">
        <v-btn @click="showAddPic({key: field.key})" color="primary" class="my-3 wide" rounded elevation="10">{{ field.label }}</v-btn>
        <ehc-image-upload v-bind="field" v-model="addPic" :title="field.label" :uploadPath="field.uploadPath"
            @upload="$emit('onImageUpload', {fileName: $event, key: field.key})" 
            :shape="field.shape"
            :size="field.size" />
        <v-img v-if="field.showInForm" :src="value" :style="field.size" max-width="200" class="my-5 mx-auto"></v-img>
        <v-btn v-if="value" text color="primary" class="float-right" @click="confirmRemove=true">
            {{removeLabel}}</v-btn>
        <ehc-alert-confirm v-model="confirmRemove" :message="field.confirmRemoveMessage"
            @confirmAction="confirmAction(field.key)"></ehc-alert-confirm>
    </div>
</template>


<script>
import EhcImageUpload from '@/components/ehc-image-upload.vue'
import EhcAlertConfirm from '@/components/ehc-alert-confirm.vue'

export default {
    components: {
        EhcImageUpload,
        EhcAlertConfirm
    },
    props: ['field', 'value'],
    data() {
        return {
            addPic: false,
            confirmRemove: false,
        }
    },
    methods: {
 /*        removeCustomItem(key) {
            console.log('removeCustomItem', key)
            // this.itemToRemove = key
            let message  = ''

            // // TODO: this is too specific, can't be used elswhere
            // if ( key = "companyImage" ) {message = 'Are you sure you want to remove this custom logo?'}
            // else { message = 'Are you sure you want to remove this custom background theme?' }

            // this.confirmRemove=true
            // this.$store.commit('setShowConfirm', true)
            // this.$store.commit('setConfirmMessage', message)  
        }, */
        async confirmAction(key) {
            console.log('confirmAction', key)
            let obj = {}
            obj[key] = null
            obj.companyImageInfo = {}
            obj.mergeData = true
            console.log("confirmAction", obj)
            this.$emit('removeImage', key)
            await this.$store.dispatch("updateProperty", obj)
            this.$store.commit('setShowConfirm', false)
        },
        showAddPic(key) {
            // console.log('addPic', this.value)
            this.addPic = true
        },
    },
    computed: {
        removeLabel() {
            if ( this.field.label.includes("Logo") ) {
                return "Remove Logo"
            } else if ( this.field.label.includes("Image") ) {
                return "Remove Theme Image"
            } else {
                return "Remove"
            }
        },
    }
}

</script>
<style>
.wide {
    width: 100%;
}
</style>
