<!-- if v-model = true it is debounced
if immediate is true there is no debounce
-->


<template>
    <v-overlay 
        :color="('color' in $attrs) ? $attrs.color : 'black'"
        :opacity="('opacity' in $attrs) ? $attrs.opacity : .9"
        :class="('class'in $attrs) ? $attrs.class: 'text-h5'"
        >
        <img :src="require('@/assets/ehicond.svg')" width="80px"></img>
       <p class="mt-5 mx-3"><slot/></p>
    </v-overlay>
</template>



<script>


export default {
    data() {
        return {
        }
    },
    watch: {
    },
    computed: {
    },
    mounted() {
    }
}
</script>


<style>

.test {
    color: rgb(58, 17, 17);
}

</style>