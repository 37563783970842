<template>
  <v-container>
  <v-row
    align="center"
    justify="center"
    width="100%"
  >

    <v-col cols="6" v-if="logo" align-self="start">
      <div class="mx-auto mt-0 ehc-branding">
        <p class="my-5 text-center"> Upload Logo</p>
        <v-img src="@/assets/icons/Close-blue.svg" width="40" class="pointer ehc-remove" @click="$emit('removeLogo')" />
        <v-img :src="logo" width="150" class="my-5 mx-auto" >
        </v-img>
      </div>
    </v-col>
    <v-col cols="6" v-else>
      <div class="mx-auto mt-0 ehc-branding">
        <p class="my-5 text-center"> Upload Logo</p>
        <v-img src="@/assets/icons/upload.svg" width="50" class="my-5 mx-auto pointer" @click="toggleAddLogo()" />
      </div>
    </v-col>

    <v-col cols="6" v-if="theme"  align-self="start">
      <div class="mx-auto mt-0 ehc-branding">
        <p class="my-5 text-center"> Upload Theme</p>
        <v-img src="@/assets/icons/Close-blue.svg" width="40" class="pointer ehc-remove" @click="$emit('removeTheme')" />
        <v-img :src="theme" width="150" class="my-5 mx-auto" />
      </div>
    </v-col>
    <v-col cols="6" v-else>
      <div class="mx-auto mt-0 ehc-branding">
        <p class="my-5 text-center">Upload Theme</p>
        <v-img src="@/assets/icons/upload.svg" width="50" class="my-5 mx-auto pointer" @click="addTheme = !addTheme" />
      </div>
    </v-col>

  </v-row>

  <ehc-image-upload 
      v-model="addLogo" 
      title="Upload Logo" 
      :uploadPath="`companyPictures/logo${Date.now()}`"
      @upload="$emit('onLogoUpload', {fileName: $event})" 
      shape="rectangle"
      ref="addLogoComponent"
      :persistent=isMobile
      />

  <ehc-image-upload 
      v-model="addTheme" 
      title="Upload Theme" 
      :uploadPath="`companyPictures/theme${Date.now()}`"
      @upload="$emit('onThemeUpload', {fileName: $event})" 
      shape="rectangle"
      :persistent=isMobile
      />

  </v-container>
</template>

<script>
import EhcImageUpload from '@/components/ehc-image-upload.vue'
import mixins from '@/mixins'


export default {
  components: {
    EhcImageUpload
  },
  mixins: [mixins],
  props: ['logo', 'theme'],
  data() {
    return {
      pageTitle: "EHC Branding",
      addTheme: false,
      addLogo: false,
    }
  },
  methods: {
    //TODO: done per Grants advice - not working yet
    //trying to get the image to clear from the component cache when the user clicks the remove button
    toggleAddLogo() {
      try {
        this.$refs.addLogoComponent.clearCropper()
      } catch (error) {
        console.log(error)
      }
      this.addLogo = !this.addLogo
    },
  },
  computed: {
  }
}
</script>

<style>

  .ehc-branding {
    /* top of parent */
    width: 150px; 
    height:200px; 
    overflow:hidden
  }

  .ehc-logo {
    /* center inside parent div with class of ehc-propavatar*/
    position: relative !important;
    overflow: hidden;
    top: 0px;
    left: 50%;
    z-index: 1;
    transform: translate(30px, 75px);
  }

  .ehc-remove {
    position: absolute !important;;
    z-index: 1;
    border: 4px solid white;
    border-radius: 50%;
    transform: translate(320%, -50%);
  }

</style>