<template>
    <v-dialog
      v-model="showAlert"
      max-width="344"
    >
      <v-card class="rounded-xl">

        <v-card-text class="text-h5 text--primary pt-4">
          {{message}}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name='confirm'></slot>
          <v-btn
            color="primary"
            text
            @click="showAlert = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ehcDialog from './ehc-dialog.vue'
export default {
  components: { ehcDialog },
  data() {
    return {
      show: true,
      dialog: false,
    }
  },
  computed: {
    showAlert: {
      get() {return this.$store.getters.showAlert},
      set(value) {this.$store.commit('setShowAlert',value)}
    },
    message: function(){
      return this.$store.getters.alertMessage
    }
  },
  methods: {
    confirmAction: function() {
      alert("confirm")
    }
  }
 
}
</script>