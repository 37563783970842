

<template>
    <v-scroll-y-reverse-transition  hide-on-leave>   
        <v-card color="white" class="pa-3 rounded-lg" elevation="0" v-if="trigger" v-bind="$attrs">
            <span class="black2--text ehc-card-content">
                <slot></slot>
            </span>
        </v-card>
    </v-scroll-y-reverse-transition>
</template> 

<script>

    export default {
        mixins: [],
        props: {
            trigger: {
                default: true,
                type: Boolean,
            }
        },
        components: {

        },
        data() {
            return {
                triggerTransition: false,
            }
        },
        created () {
        },  
        watch: {

        },
        computed: {
        },
        methods: {
        },
        mounted(){
        },
        beforeDestroy() {
        }
    }
</script>

<style>

.ehc-card-content h1 {
    font-weight: bold;
    font-size: 22;
}

.ehc-card-content h2 {
    font-weight: bold;
    font-size: 19;
}

.ehc-card-content > * {
    line-height: 1.3;
    font-size: 20px;
    font-weight: normal;
    }

.contentImage {
    max-width: 100% !important;
}

.v-card__text {
    overflow: hidden;
}

.ehc-card-content .video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.ehc-card-content .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div.video:not(:has(*)) {
    display: none;
}
</style>


