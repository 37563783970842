<template>
    <v-dialog
      v-model="showConfirm"
      width="500"
    >
      <v-card>
        <v-card-text class="text-h4 grey lighten-2 text-center">
        </v-card-text>

        <v-card-text 
          class="text-h5 grey lighten-2 text-center" 
          v-html="message" />

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="confirm() "
          >
              Confirm
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="showConfirm = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ehcDialog from './ehc-dialog.vue'
export default {
  components: { ehcDialog },
  props: ['item', 'value', 'message'],
  data() {
    return {
    }
  },
  computed: {
    showConfirm: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    },
  },
  methods: {
    confirm() {
      console.log("confirm")
      this.showConfirm = false
      this.$emit('confirmAction')
      
    }
  }

}
</script>