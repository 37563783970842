<!-- 
    example:
        <template>
            <ehc-form 
                v-model="formData" 
                :meta="meta" 
                :shakeInvalid="shakeVariable" - (optional) set to true to shake invalid inputs
                @submit="somefunction()"
                @valid="isValid = $event" - (optional) returns whether or not the form is valid
                />
        </template>
        <script>
            data() {
                return {
                    meta: [
                        {type: "text",          label: "name",                      key: "name"},
                        {type: "editor",        label: "name",                      key: "name"},
                        {type: "slot",          slotName: "avatar"}, 
                        {type: "link",          label: "name",                      key: "name"},
                        {type: "email",         label: "email",                     key: "email"},
                        {type: "textArea",      label: "Description of problem",    key: "description"},
                        {type: "subheader",     label: "subheader text"},
                        {type: "fileInput",     label: "upload screenshot(s)",      key: "upload",          multiple:true},
                        {type: "phoneNumber",   label: "Phone Number",              key: "phoneNumber"},
                        {type: "button",        label: "submit",                    key: "submit",          emitOnClick: "submit"},
                        {type: "switch",        label: "switch me",                 key: "isSwitched",      required: true, errorMessage: "you must switch"}, //by default it just says "required" but errorMessage overrides that message
                        {type: "select",        label: "select one",                options: ["first opt", "second opt"], required: true, errorMessage: "you must select something"}   
                        {type: "imageUpload",   label: "name",                      key: "name",            uploadPath: `filename with path`,   shape: 'rectangle or circle', size: {width: 400, height: 400},  adminOnly: true/false},
                        {type: "divider"},
                    ],
                }
            },
            methods: {
                somefunction() {
                    do.something.with(this.formData)
                }
            }
        </script>

        FIXME: currently when chrome autofills the form it overlaps the labels. figure out how to fix that. 
 -->


<template>
    <div class="outside mb-5">
        <v-container>
            <v-form 
                v-model="valid"
                ref="ehcForm"
                @submit.prevent
                >
            <slot></slot>
            <template v-for="(field, fieldIndex) in meta">
                <v-row :key="fieldIndex">

                <!-- TEXT -->
                    <v-text-field   ref='input'
                                    v-if="field.type == 'text'" 
                                    :color="color"
                                    :rules="inputRules(field)"
                                    outlined 
                                    :dense = dense
                                    :maxlength=field.maxlength
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    <template slot="label">
                                        <span v-html="field.label"></span>
                                    </template>
                                    </v-text-field>

                <!-- RICH TEXT EDITOR ( ckeditor) -->
                    <ehc-editor 
                                    v-else-if="field.type === 'editor'"
                                    v-model="data[field.key]"
                                    class="wide"
                                    >{{field.label}}
                                    </ehc-editor>
                                    <!---No @blur or @change needed if we use a save button--->

                <!-- NUMBER -->
                    <v-text-field   ref='input'
                                    v-else-if="field.type == 'number'" 
                                    type="number"
                                    :color="color"
                                    :rules="inputRules(field)"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>

                <!-- TEXTSINGLE -->
                    <v-text-field   ref='input'
                                    v-else-if="field.type == 'textSingle'" 
                                    append-outer-icon="mdi-send"
                                    :color="color"
                                    :rules="inputRules(field)"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @click:append-outer="$emit(field.emitOnClick)"
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>

                <!-- EMAIL -->
                    <v-text-field   v-else-if="field.type == 'email'" 
                                    :rules="emailRules(field)"
                                    :color="color"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>   

                <!-- PASSWORD -->
                    <v-text-field   v-else-if="field.type == 'password'" 
                                    :rules="inputRules(field)"
                                    :color="color"
                                    :dense = dense
                                    outlined 
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-text-field>  

                <!-- ALERT -->
                    <v-alert 
                        v-else-if="field.type == 'alert'" 
                        type="info" 
                        width="100%" 
                        dense 
                        :color="('color' in field) ? field.color : ''">
                        {{field.label}}
                    </v-alert>
                    <hr v-else-if="field.type == 'divider'" dark class="my-3"/>

                <!-- GOOGLE AUTOCOMPLETE -->
                    <ehc-google-autocomplete 
                        v-else-if="field.type == 'mapsAutocomplete'" 
                        :label="field.label"
                        v-model="data[field.key]">
                    </ehc-google-autocomplete>   

                <!-- INT PHONE NUMBER -->
                    <ehc-int-phone-input
                                    v-else-if="field.type == 'intPhoneNumber'"
                                    v-model="data[field.key]" 
                                    :color="color"
                                    :dense = dense
                                    outlined
                                    v-bind="field"
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </ehc-int-phone-input>                                                                      

                <!-- PHONE NUMBER -->
                    <ehc-phone-input   
                                    v-else-if="field.type == 'phoneNumber'"  
                                    :props="field"
                                    :color="color" 
                                    :dense = dense
                                    v-model="data[field.key]" 
                                    @demaskedOut="data[field.key] = $event"
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </ehc-phone-input>

                <!-- TEXT AREA -->
                    <v-textarea   v-else-if="field.type == 'textArea'" 
                                    outlined
                                    :color="color" 
                                    :rules="inputRules(field)"
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="$emit('blur', {key: field.key, value: data[field.key]})"
                                    @change="$emit('change', {key: field.key, value: data[field.key]})">
                                    </v-textarea>

                <!-- LINK -->
                    <v-text-field   v-else-if="field.type == 'link' && !field.hidden"  
                                    :rules="linkRules(field)"
                                    :color="color"
                                    outlined 
                                    :dense = dense
                                    v-bind="field" 
                                    v-model="data[field.key]" 
                                    @blur="onBlurLink(field.key, data[field.key])"
                                    @change="onChangeLink(field.key, data[field.key])"
                                    >
                                    </v-text-field>                                       

                <!-- FILE INPUT -->
                    <ehc-file-input   
                                            :color="color" 
                                            v-else-if="field.type == 'fileInput'" 
                                            v-model="data[field.key]" 
                                            :props="field"></ehc-file-input>

                <!-- IMAGE UPLOAD -->
                    <ehc-form-image-upload
                        v-else-if="field.type == 'imageUpload'"
                        v-model="data[field.key]" 
                        :field="field" 
                        @onImageUpload="$emit('onImageUpload', $event)"
                        @removeImage="$emit('removeImage', $event)"
                        >
                    </ehc-form-image-upload>

                <!--- BRANDING ---> 
                    <!-- <ehc-branding v-else-if="field.type == 'branding'" 
                        :field="field" 
                        :user="user"
                        @removeImage="$emit('removeImage', $event)"
                        @onImageUpload="$emit('onImageUpload', $event)"
                        >
                    </ehc-branding>     -->

                <!-- SELECT -->
                    <v-select
                        v-else-if="field.type === 'select'"
                        v-model="data[field.key]"
                        :value = null
                        :color="color"
                        :items="field.options"
                        :label="field.label"
                        outlined
                        :dense = dense
                        :rules="inputRules(field)"
                        @change="$emit('change', {key: field.key, value: data[field.key]})">
                    ></v-select>                                            

                <!-- SWITCH -->
                    <div v-else-if="field.type === 'switch'" style="width:100%;height:40px">
                            <span :class="('labelClass' in field) ? field.labelClass : 'float-left'" v-if="field.prependLabel">{{field.label}}</span>  
                            <span class="float-right mt-n5">
                            <v-switch
                                :dense = dense
                                class="float-right"
                                color="blue darken-2"
                                v-bind="field"
                                v-model="data[field.key]"
                                :rules="inputRules(field)"
                                :label="!field.prependLabel ? field.label : ''"
                                @change="$emit('change', {key: field.key, value: data[field.key]})">
                            ></v-switch>                    
                            </span>
                    </div>

                <!-- SWITCH2 -->
                    <div v-else-if="field.type === 'switch2'" style="width:100%;height:40px">
                            <v-switch
                                :dense = dense
                                color="green darken-2"
                                v-bind="field"
                                v-model="data[field.key]"
                                :rules="inputRules(field)"
                                @change="$emit('change', {key: field.key, value: data[field.key]})">
                            ></v-switch>                    
                    </div>

                <!-- SUBMIT -->
                    <v-btn
                        v-else-if="field.type === 'submit'"
                        v-bind="field"
                        color = 'button'
                        dark 
                        :disabled = field.disabled
                        :block = "('block' in field) ? field.block : true"
                        :x-large = "('xLarge' in field) ? field.xLarge : false"
                        :class = "('class' in field) ? field.class : null"                        :loading = field.loading
                        @click="$emit('submit')"
                        >
                        <strong>{{field.label}}</strong>
                    </v-btn> 
                    <v-btn
                        :color="(field.color) ? field.color : color"
                        block
                        dark
                        v-else-if="field.type === 'button'"
                        v-bind="field"
                        @click="$emit(field.emitOnClick)"
                        >
                        <strong>{{field.label}}</strong>
                    </v-btn>

                <!-- CAPTION  -->
                    <v-subheader 
                        v-else-if="field.type === 'caption'" 
                        :class="('class' in field)? field.class : 'text-caption mt-n5 mb-5'">
                        {{field.label}}
                    </v-subheader>   


                <!-- SUBHEADER -->
                    <v-subheader 
                        v-else-if="field.type === 'subheader'" 
                        :class="('class' in field)? field.class : 'text-h6 mb-5 text-center'">
                        {{field.label}}
                    </v-subheader>   

                <!-- HEADER -->
                    <div v-else-if="field.type === 'header'">
                        <p 
                            v-if="field.label" 
                            :class="('labelClass' in field)? field.labelClass : 'text-h6 font-weight-bold mb-9'" 
                            v-html="field.label" />
                        <p 
                            v-if="field.instructions" 
                            :class="('instructionsClass' in field)? field.instructionsClass : 'text-body-1 font-weight-regular mb-1'" 
                            v-html="field.instructions" />
                    </div>    

                    <template v-else-if="field.type === 'slot'">
                        <slot :name="field.slotName"></slot>
                    </template>

                <!-- SPACER -->
                    <div v-else-if="field.type === 'spacer'" :class="('class' in field)? field.class : 'mb-5'">&nbsp;</div>    
                                                               
                </v-row>
            </template>
            </v-form>
        </v-container>

    </div>
</template>

<script>
    import EhcFileInput from '@/components/form/ehc-file-input.vue'
    import EhcPhoneInput from '@/components/form/ehc-phone-Input.vue'
    import EhcIntPhoneInput from '@/components/form/ehc-Int-phone-input.vue'
    import EhcGoogleAutocomplete from '@/components/form/ehc-google-autocomplete.vue'
    import VueGoogleAutocomplete from "vue-google-autocomplete"
    import EhcEditor from '@/components/ehc-editor.vue'
    import EhcFormImageUpload from '@/components/form/ehc-form-image-upload.vue'
    import EhcBranding from '@/components/form/ehc-branding.vue'
    
    export default {
        mixins: [],
        props: {
            meta: Array,
            value: Object,
            shakeInvalid: {type: Boolean, default: false},
            dense: {type: Boolean, default: false},
            color: {type: String, default: "button"},
            user: {type: Object, default: null},
            useAutoComplete: {type: Boolean, default: false},
        },
        components: {
            EhcGoogleAutocomplete,
            EhcFileInput,
            EhcPhoneInput,
            EhcIntPhoneInput,
            VueGoogleAutocomplete,
            EhcEditor,
            EhcFormImageUpload,
            EhcBranding
        },
        data: () => ({
            data: {},
            valid: null,
            addPic: false,
            addPic2: false
        }),
        created () {
            this.data = this.value
        },
        async mounted() {
 
        },          
        watch: {
            shakeInvalid() {
                this.$refs.ehcForm.validate()
            },
            valid(val) {
                this.$emit('valid', val)
            },
            value() {
                this.data = this.value
            },
            data(val) {
                this.$emit('input', val)
            }
        },
        computed: {

        },
        methods: {

            // rules
            emailRules(field) {
                let rule = []
                let errorMessage = (field.errorMessage) ? field.errorMessage : "required"
                if (field.required) { rule.push(v => !!v || errorMessage)}
                rule.push(v => /(.+@.+\..+)|^(?![\s\S])/.test(v) || 'Must be valid Email')

                return rule
            },
            linkRules(field) {
                let rule = []
                let errorMessage = (field.errorMessage) ? field.errorMessage : "required"
                if (field.required) { rule.push(v => !!v || errorMessage)}
                rule.push(v => /^$|null|([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)|^(?![\s\S]))/.test(v) || 'Must contain a valid URL')

                return rule
            },
            inputRules(field) {
                let rule = []
                let errorMessage = (field.errorMessage) ? field.errorMessage : "required"
                if (field.required) { rule.push(v => !!v || errorMessage)}
                try {
                    if (field.maxCharacters) {rule.push(v => v.length <= field.maxCharacters || 'Max ' + field.maxCharacters + ' characters')}
                } catch (e) {
                    console.log(e)
                }
                if (field.maxCharacters) {rule.push(v => v.length <= field.maxCharacters || 'Max ' + field.maxCharacters + ' characters')}

                return rule
            },
            onBlurLink(key,newValue){
                this.$emit('blur', {key: key, value: newValue})
            },
            onChangeLink(key,newValue){
                this.$emit('change', {key: key, value: newValue})
            },

        },
    }
</script>

<style scoped>
.border {
    border: solid gray 1px;
    border-radius: 5px;

}
.form-control:focus {
  outline: none;
}

.wide {
    width: 100%;
}

.submit-button-responsive {
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
}

@media (min-width: 1024px) {
    .submit-button-responsive {
        width: 50%;
    }
}

</style>


