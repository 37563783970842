import firebase from 'firebase'
import backupCardItems from "./cardItems.json"

// Used to set the icon for each card item
let cardIcons = {
  "propertyinfo": "property-info.png",
  "nearby": "nearby.svg",
  "areaguide": "area-guide.svg",
  "bookagain": "book-again.svg",
  "contact": "contact-us.svg",
  "deals": "deals.svg",
}

// Used to hide card items from the home page
let hideCardItemLabels = ['Deals']

export default{
  state: {
    cardItems: [],  // this is the array of cards that are displayed on the home page
  },
  getters: {
    cardItems: state => state.cardItems.sort( (a, b) => a.sortOrder - b.sortOrder ).filter( (el) => !hideCardItemLabels.includes(el.label) ),
    //  I don't think this is used anywhere
    // cardItemsRoutesArray: state => {
    //   let array = []
    //   state.cardItems.forEach( (el) => array.push(el.link) )
    //   return array
    // },
  },
  mutations: {
    setCardItems (state,payload){
      state.cardItems = payload
    },
  },
  actions: {
    //get card items from defaults
    getCardItems(context,propertyId){
      //console.log"getCardItems")
        let cardItems = []
        const cardItemsRef = firebase.firestore().collection("defaults").doc("cardItems").collection("subsections")
        cardItemsRef.get()
        .then( (docs) => {
          docs.forEach( (doc) => {  
            let obj = doc.data()
            obj.icon2 = cardIcons[obj.id] || null
            //console.logobj)
            cardItems.push(obj)
        })
          context.commit("setCardItems",cardItems)
        })
        .catch( (err) => {
          console.log(err)
          context.commit("setCardItems",backupCardItems) // if there is an error, use the backup card items from the json file
         } ) 
    },
    // get card items from property customCardItems collection
    getCustomCardItems(context,propertyId){
      console.log("getCustomCardItems from", propertyId)
      let cardItems = []
      let customCardItemsRef = firebase.firestore().collection("properties").doc(propertyId).collection("customCardItems")
      customCardItemsRef.get()
      .then( (docs) =>{
        docs.forEach((doc) => {
          let obj = doc.data()
          obj.id = doc.id
          obj.icon2 = cardIcons[obj.id] || null
          cardItems.push(obj)
        })
      })
      console.log("getCustomCardItems:", cardItems)
      context.commit("setCardItems",cardItems)
    },

    copyCollection(context,payload){
      console.log("copyCollection", payload)
      //call a cloud function to copy the collection
      let copyCollection = firebase.functions().httpsCallable('copyCollection')
      copyCollection(payload)
    },

    // update properties custorm card items with new data
    updateCustomCardItems(context,payload){
      // //console.log"updateCustomCardItems", payload)
      let cardItems = context.getters.cardItems
      cardItems.forEach( (el) => {
        firebase.firestore().collection("properties").doc(payload.propertyId).collection("customCardItems").doc(el.id).set(el, {merge:true})
        .then( () => { 
          //console.log"updated") 
        } )
        .catch( (error) => { console.log("error:", error) } )
      })
    },
    // only used to load card items into defaults - probably never needed again
    loadCustomCardItems(context,propertyId){
      console.log("loadCardItems", propertyId)
      let cardItems = context.getters.cardItems
      console.log("cardItems ",cardItems)
      //TODO: Test the creation of customCardItems
      cardItems.map(el => {
        el.id = el.link.toLowerCase().replace(/\s+/g, '')
        el.icon2 = cardIcons[el.link.toLowerCase()] || null
        el.icon = cardIcons[el.link.toLowerCase()] || null
        return el
      })
      console.log("cardItems ",cardItems)
      cardItems.forEach( (el) => {
        firebase.firestore().collection("properties").doc(propertyId).collection("customCardItems").doc(el.id).set(el)
       }
      )
    },
  }
}