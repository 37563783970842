<template>
   <v-btn 
          small 
          class="ma-auto" 
          fab 
          icon 
          @click.stop="$emit('editBrand')">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
</template>

<script>
import mixins from '@/mixins'

export default {
  mixins: [mixins],
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>