<template>
  <v-btn
    dark
    @click="submit" 
    :class="ehcClass + ` ehc-button ${invert ? 'ehc-invert' : ''}` + ' notranslate'"
    v-bind="$attrs"
    :color="color"
    :elevation="0"
    :loading="loading"
  >
  <v-img :src="`${iconPath}${icon}`" v-if="icon">
  </v-img>
  
    <slot></slot>
    {{text}}
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: String,
    text: String,
    loading: {type: Boolean, default: false},
    iconPath: {type: String, default: '/img/'},
    invert: {type: Boolean, default: false},
    color: {type: String, default: ''},
    ehcClass: {type: String, default: 'mb-1'}
  },
  methods: {
    submit: function(){
      this.$emit('submit')
    }
  }
}
</script>

<style>

.ehc-button-small .v-progress-circular {
  height: 10px !important;
  width: 10px !important;
}

.ehc-button.ehc-invert {
    background-color: #ecf4ff !important;
    color: #3C88FF !important;


}

.ehc-button {
  height: 40px !important;
  border-radius: 12px !important;
  /* set button color */
  background-color: #3C88FF !important;
  /* justify center */
  justify-content: center !important;
}

.ehc-button .v-btn__content {
  justify-items: center !important;
  justify-content: center !important;
}



</style>