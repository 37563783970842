<template>
<v-card width="100%" class="mb-5">
    <v-card 
        v-if="showInput"
        elevation="0"
        width="100%"
        tile
    >    
        <v-card-subtitle 
          class="border"
          tile  
          flat
          outlined >
            <form autocomplete="off" style="width:100%" v-on:submit.prevent><!---needed to turn off browser autofill--->
            <vue-google-autocomplete 
                ref="address" 
                id="map" 
                classname="form-control" 
                :placeholder="label" 
                @placechanged="getAddressData"
                @click="blur"
                @blur="blur"
                @focus="focus"
                style="width:100%" 
                autocomplete="off"
                />
            </form>    
        </v-card-subtitle>
    </v-card>
    <v-card v-if="showResults" class="border">    
      <v-card-title @click="showResults=false; showInput=true">{{data.formatted_address}}</v-card-title>        
    </v-card>  
</v-card>    
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete"

export default {
  components: {
    VueGoogleAutocomplete
  },

  props: {
    value: {type: Object, default: {
      formatted_address: ""
    }},
    label: String
  },

  data() {
    return {
      showInput: false,
      showResults: true,

    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(val) {
        console.log("change data ", val)
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
        console.log("addressData ",addressData)
        console.log("placeResultData ",placeResultData)
        let data = {}       
        data.formatted_address = placeResultData.formatted_address.replace('undefined ', '')
        data.adr_address = placeResultData.adr_address
        data.lng = placeResultData.geometry.location.lng()
        data.lat = placeResultData.geometry.location.lat()
        data.place_id =  placeResultData.place_id
        data.name = placeResultData.name
        data.state = placeResultData.state || "" //fixes issue with undefined state (try Chalidon Street 1, 73100, Chania, Greece)
        let parsedData = this.parseAddressComponents(placeResultData)
        data = {...data,...parsedData}
        console.log("data from placeResultData ",data)

        this.$emit('input', data)
    },  
    parseAddressComponents: function (place) {
        const address = {}

        place.address_components.forEach(component => {
            let { long_name, types } = component

            if (types.includes('street_number')) {
            address.streetNumber = long_name
            } else if (types.includes('route')) {
            address.street = long_name
            } else if (types.includes('neighborhood')) {
            address.neighborhood = long_name
            } else if (types.includes('locality')) {
            address.city = long_name
            } else if (types.includes('administrative_area_level_2')) {
            address.county = long_name
            } else if (types.includes('administrative_area_level_1')) {
            address.state = long_name
            } else if (types.includes('country')) {
            address.country = long_name
            } else if (types.includes('postal_code')) {
            address.zip = long_name
            }
            address.address = `${address.streetNumber} ${address.street}`
        })

        return address
      },
    keypress: function(e) {
      alert(e)
    },
    blur: function() {
      //TODO: I'm not sure why commenting these out fixes the problem
      // this.showInput = false
      // this.showResults = true
    },
    focus: function() {
      //TODO: I'm not sure why commenting this out fixes the problem
      // this.showInput = true
    }  
  }
}


</script>

<style scoped>
.border {
  border: solid gray 1px
}
.form-control:focus {
  outline: none;
}

.border{
  box-shadow: none !important;
}

</style>