<script>
import firebase from 'firebase'


export default {
  data () {
    return {

    }
  },
  created() {
  },
  methods:{
      async apiNearbyInfo(payload) {
        // console.log("apiNearbyInfo", payload)
        let nearbyObj = []
        const nearByRef = firebase.firestore().collection('properties').doc(payload.id).collection(payload.type)

        return await nearByRef.get()
        .then((docs) => {
          docs.forEach((doc)=>{
                let nearby = doc.data()
                // console.log(`got ${payload.type}`, nearby)
                nearbyObj.push(nearby)
          })
          return nearbyObj
        })
        .then( (nearbyObj) => {
          // console.log("nearbyObj", nearbyObj)
          return nearbyObj
        })
            // if (doc.exists) {
            //     let nearby = doc.data()
            //     console.log("got nearby", nearby)
            //     return nearby
            // } else {
            //     console.log("nearby does not exist")
            // }
        },
      async apiGetUser(id) {
        // console.log("apiGetUser", id)
        const userRef = firebase.firestore().collection('users').doc(id)
        return await userRef.get().then((doc) => {
            if (doc.exists) {
                let user = doc.data()
                // console.log("got user", user)
                return user
            } else {
                console.log("user does not exist")
            }
        })
      },
      async apiUpdateUser(id, payload) {
        // console.log("apiUpdateUser", id, payload)
        const userRef = firebase.firestore().collection('users').doc(id)

        return await userRef.update(payload).then((resp) => {
          // console.log('apiUpdateUser success!', resp)
          return true
        })
      },
      updatePageGroup(propID, groupName) {
        const propRef = firebase.firestore().collection('properties').doc(id)
        .collection('groupName').doc('subsections')
      },
      async apiUpdateProperty(id, payload) {
        // console.log("apiUpdateProperty", id, payload)
        const propRef = firebase.firestore().collection('properties').doc(id)

        return await propRef.update(payload).then((resp) => {
          // console.log("apiUpdateProperty success", resp)
          return true
        })
      },
      
      async apiGetNearbyDirect(payload){
        let nearbyDirect = [] 
        let defaultData = {
          location: '33.7489954,-84.3879824',
          radius: 1000,
          keyword: 'Food + Drink',
          useTestData: true,
        }
        let data = payload || defaultData
        let getNearby = firebase.functions().httpsCallable('getNearbyDirect')
          return await getNearby(data)
            .then((res) => {
              nearbyDirect =  res.data.result
              // console.log("apiGetNearbyDirect in api ", nearbyDirect)
              return(nearbyDirect)
            })
            .catch((error) => {
              console.log("apiGetNearbyDirect error ", error)
            })
      }, 

      async apiGetDistanceMatrix(payload){
        console.log("getDistanceMatrix", payload)
        let getDistanceOnCall = firebase.functions().httpsCallable('getDistanceOnCall')
        return await getDistanceOnCall(payload)
          .then((res) => {
            console.log("apiGetDistanceMatrix res ", res)
            let matrix = res.data.result.rows[0].elements
            console.log("apiGetDistanceMatrix in api ", matrix)
            return matrix
          })
          .catch((error) => {
            console.log("apiGetDistanceMatrix error ", error)
          })
      },

      async apiRemoveFromStorage(storageUrl) {
        const path = decodeURIComponent(storageUrl.match(/\/o\/(.*)\?alt/)[1])
        let storageRef = firebase.storage().ref()
        let imageRef = storageRef.child(path)
        return await imageRef.delete()
          .then((res) => {
            // console.log("removeFromStorage success", res)
            return true
          })
          .catch((error) => {
            console.log("removeFromStorage error", error)
          })
      },

      async apiLogGuestVisit(propertyId) {
        const db = firebase.firestore(); // Assuming you have initialized Firebase Firestore
        const timestamp = firebase.firestore.Timestamp.now(); // Get the current Firestore Timestamp

        const logData = {
          propertyId: propertyId,
          dateTime: timestamp,
        };

        db.collection("logGuestVisits")
          .add(logData)
          .then(() => {
            console.log("Log saved successfully!");
          })
          .catch((error) => {
            console.error("Error saving log:", error);
          });
      },

    async apiGetAppSettings() {
      //console.log("apiGetAppSettings")

      await this.apiGetDoc("settings", "globalAppSettings").then((data) => {
        //console.log("commit app settings", data)

        this.$store.commit('setAppSettings', data)
      })
    },

        //GENERIC get and update doc by ID
    async apiGetDoc(collection, docID) {
      console.log("apiGetDoc", collection, docID)

      const docRef = firebase.firestore().collection(collection).doc(docID)
      return await docRef.get().then((doc) => {
        if (doc.exists) {
          let data = doc.data()
          // console.log("got doc", data)
          return data
        } else {
          console.log("data doesn't exist")
        }
      })
    },
    async apiUpdateDoc(collection, docID, payload) {
      // console.log("apiUpdateDoc", collection, docID, payload)

      const docRef = firebase.firestore().collection(collection).doc(docID)

      return await docRef.update(payload).then((resp) => {
        //console.log("updated", resp)
        return true
      })
    },


  },
  computed: {

  }
}
</script>