<template>
  <div>
    <slot />
    <ckeditor
      v-if="editor === 'ckeditor'"
      v-model="data"
      :config="editorConfig"
      @blur="$emit('blur', data)"
      @input="$emit('change', data)"
      @focus="onFocus($event)"
      @contentDom="onContentDom($event)"
      @dialogDefinition="onDialogDefinition($event)"
      @onChange="onChange($event)"
      @onOk="onOk($event)"
    >
    </ckeditor>

    <!-- TODO: We can remove wysiwyg when ckeditor proves to work for us -->
  </div>
</template>

<script>
import mixins from "@/mixins";
import CKEditor from "ckeditor4-vue";

export default {
  name: "app",
  mixins: [mixins],
  props: ["value"],
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      data: {},
      editor: "ckeditor",
      editorConfig: {
        toolbar: [
          {
            name: "basicstyles",
            groups: ["basicstyles", "cleanup"],
            items: ["Bold", "Underline", "Italic"],
          },
          {
            name: "paragraph",
            groups: ["list", "indent", "align"],
            items: [
              "NumberedList",
              "BulletedList",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
            ],
          },
          { name: "styles", items: ["Format"] },
          { name: "colors", items: ["TextColor", "BGColor"] },
          { name: "links", items: ["Link", "Unlink"] },
        ],
        removeButtons: "Subscript,Superscript,LinkType,Protocol",
        allowedContent: true,
        linkDefaultProtocol: "https://",
        extraPlugins: "justify,autogrow,colorbutton",
        autoGrow_maxHeight: 500,
        on: {
          notificationShow: function (evt) {
            evt.cancel();
          },
          notificationUpdate: function (evt) {
            evt.cancel();
          },
        },
      },
    };
  },
  methods: {
    onContentDom(event) {
      event.editor.on("change", this.onChange);
    },
    onDialogDefinition(event) {
      event.data.dialog.on("ok", this.onOk);
    },
    onChange() {
      this.$emit("change", this.data);
    },
    onOk() {},
    onFocus($event) {
      console.log("onFocus: ", $event);
    },
  },
  created() {
    this.data = this.value;
    if (!this.userIsAdmin) {
      this.editorConfig.removeButtons = "Subscript,Superscript";
    }
  },
  watch: {
    value() {
      this.data = this.value;
    },
    data(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style>
#cke_252_label {
  color: white;
}
</style>

<!-- Note: the protocol defaults to https://. -->
<!-- Css style hides the protocol labe and box and the first td in the tr -->
<!-- I think that ekeditor might be setting selectors dynamically which would break these style hacks. -->