import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GuestInfo from '../views/GuestInfo.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/guestinfo/:propertyId',
    name: 'GuestInfo',
    component: GuestInfo,
  },
  {
    path: '/propertynew',
    name: 'PropertyNew',
    component: () => import(/* webpackChunkName: "propertynew" */ '../views/PropertyNew.vue')
  },
  {
    path: '/propertyinfo',
    name: 'PropertyInfo',
    component: () => import(/* webpackChunkName: "propertyinfo" */ '../views/PropertyInfo.vue')
  },
  {
    path: '/brandedit',
    name: 'BrandEdit',
    component: () => import(/* webpackChunkName: "brandedit" */ '../views/BrandEdit.vue')
  },
  {
    path: '/properties',
    name: 'Properties',
    component: () => import(/* webpackChunkName: "properties" */ '../views/Properties.vue')
  },
  {
    path: '/areaguide',
    name: 'AreaGuide',
    component: () => import(/* webpackChunkName: "areaguide" */ '../views/AreaGuide.vue')
  },
  {
    path: '/nearby',
    name: 'Nearby',
    component: () => import(/* webpackChunkName: "nearby" */ '../views/Nearby.vue')
  },
  {
    path: '/deals',
    name: 'Deals',
    component: () => import(/* webpackChunkName: "deals" */ '../views/Deals.vue')
  },
  {
    path: '/bookagain',
    name: 'BookAgain',
    component: () => import(/* webpackChunkName: "bookagain" */ '../views/BookAgain.vue')
  },
  {
    path: '/houserules',
    name: 'HouseRules',
    component: () => import(/* webpackChunkName: "houserules" */ '../views/HouseRules.vue')
  },
  {
    path: '/weather',
    name: 'Weather',
    component: () => import(/* webpackChunkName: "weather" */ '../views/Weather.vue')
  },
  {
    path: '/images',
    name: 'Images',
    component: () => import(/* webpackChunkName: "images" */ '../views/Images.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue')
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "signin" */ '../views/auth/Signin.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "signin" */ '../views/Feedback.vue')
  },
  {
    path: '/login/:propertyId',
    name: 'Login',
    component: () => import(/* webpackChunkName: "signin" */ '../views/auth/Signin.vue')
  },
  {
    path: '/:propertyId',
    name: 'GuestInfo',
    component: GuestInfo
  },
  // add a default router to home
  {
    path: '*',
    name: 'Home',
    component: Home
  } 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

 
export default router
