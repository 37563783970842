<template>

  <div class="text-center" v-if="propertyIsLoaded">

    <ehc-page>

    <component-avatar-property-name/>    
    <ehc-card>
      <ehc-card-toolbar v-if="property.showName" :title="property.name"/>
      <ehc-card-content>

      <p class="text-h6 text-md-h5 text-left" >
        Please take a few seconds to tell us more about your stay. Then after submission, you can view our welcome book & area guide.  
      </p>

      <ehc-form 
              v-model="formData" 
              :meta="fields" 
              @submit="submit"
              @valid="isValid=$event"
              :shakeInvalid="shakeInvalid"></ehc-form>

      <v-dialog
        v-model="showThankyou"
          max-width="800"
          center
          overlay-opacity = .8
          overlay-color="blue-grey"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          transition="dialog-top-transition"
      >
        <v-card
        >
          <v-card-text center class="pt-5">
            <p class="text-h5 text-md-h4 dialogTitle" >
              Thank You!  
            </p>
            <v-btn
              @click="viewPropertyGuide"
              block
              color="primary"
            >
              View Property Guide
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>


    </ehc-card-content>
    </ehc-card>  
    </ehc-page>
    </div>


</template>

<script>
import mixins from "@/mixins"
import ComponentAvatarPropertyName from '../components/component-avatar-property-name.vue'
import EhcCardContent from '../components/ehc-card-content.vue'
import EhcCardToolbar from "../components/ehc-card-toolbar.vue"

  export default {
  components: { ComponentAvatarPropertyName, EhcCardContent, EhcCardToolbar },
    name: 'Home',
    mixins: [mixins],
    data() {
      return {
        isValid: false,
        shakeInvalid: false,   
        triggerTransition: false,
        toolBarBackground: "transparent",
        pageTitle: "Guest Information",
        guestForDays: 90,
        guestCount: 30,
        showThankyou: false,
        formData: {},
        propertyIsLoaded: false,
        fields: [
          {
            type: "text",          
            label: "First Name",                      
            key: "fname",
            required: true
          },
          {
            type: "text",          
            label: "Last Name",                      
            key: "lname",
            required: true
          },
          {
            type: "intPhoneNumber",          
            label: "Phone",                      
            key: "phone",
            required: true
          },
          {
            type: "email",          
            label: "Email",                      
            key: "email",
            required: true
          },
          {
            type: "select",          
            label: "Number of Guests",                      
            key: "numberOfGuests",
            required: true,
            errorMessage: "you must select number of guests", 
            options: [...Array(31).keys()].slice(1) 
            // slice removes the zero option. We can use this.guestCount+1 but will need to move this array to a computed property
          },
          {
            type: "textArea",          
            label: "Tell us about your stay...",                      
            key: "yourStay",
            required: true
          },
          {
            type: "switch2",          
            label: "I have read & agree to your house rules.",                      
            key: "houseRules",
            errorMessage: "you must agree to house rules",
            required: true
          },
          { type: "spacer", class: "mb-0"},
          {
            type: "switch2",          
            label: "I understand my info. may be used for communication purposes and I will have the option to unsubscribe at any time.",                      
            key: "optIn",
            errorMessage: "you must agree to our information use policy",
            required: true
          },
          { type: "spacer", class: "mb-10" },
          { type: "spacer", class: "mb-5" },
          {
            type: "button",
            label: "Submit",
            emitOnClick: "submit",
          },
        ],
      }
    },
    methods: {
      submit: async function(){
        if (this.isValid) {
          console.log("ITS VALID!")
          let obj = this.formData
          // obj.uid = this.$store.getters.user.data.uid
          obj.propertyId = this.$store.getters.propertyId
          obj.propertyName = this.$store.getters.property.name
          obj.propertyCity = this.$store.getters.property.city
          obj.propertyState = this.$store.getters.property.state
          obj.propertyUid = this.$store.getters.property.uid
          console.log("guest data ", obj)
          await this.$store.dispatch("saveGuestInfoIfUniqueEmailAndPhone",obj)
          this.setGuest() 
          this.goToRoute('Home') 
        } else {
          console.log("IT'S INVALID!")
          this.shakeInvalid = !this.shakeInvalid
        }
      },
      setGuest: function(){ 
        let d = Date.now()
        localStorage.setItem("EHGuest", d) 
      },
      isFirstTimeGuest: function(){
        if ( !localStorage.getItem("EHGuest") ) { console.log("no"); return true }
        let expires = localStorage.getItem("EHGuest") + (960000 * this.guestForDays)
        let today = Date.now()
        let isFirstTimeGuest = today - expires ? false : true
        // console.log("isFirstTimeGuest: ",isFirstTimeGuest)
        return isFirstTimeGuest
      },     
      viewPropertyGuide: function(){
        this.goToRoute('Home')
      },



    },
    computed: {
      property() {
        return this.$store.getters.property
      }
    },
    watch:{
      property(){
        this.propertyIsLoaded = true // keeps the page from flashing on load
        if ( (!this.isFirstTimeGuest() || this.property.noguestinfo) && !this.$route.query.showguestinfo ) { this.goToRoute("Home") }
      }
    },
    created(){
      // if ( !this.isFirstTimeGuest() || this.property.noguestinfo ) { this.goToRoute("Home")}
    },
    mounted(){
      this.triggerTransition = true
    }
  }
</script>

<style scoped>
.dialogTitle {
  background-color: black;
  color: white;
  text-align: center !important;
  border-radius: 15px;
  width: 100%;
}

.fade3-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade3-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style>
.v-input__slot {
  align-items: normal !important;
}

</style>