import { render, staticRenderFns } from "./ehc-white-label-footer.vue?vue&type=template&id=fc4fa4ce&scoped=true"
import script from "./ehc-white-label-footer.vue?vue&type=script&lang=js"
export * from "./ehc-white-label-footer.vue?vue&type=script&lang=js"
import style0 from "./ehc-white-label-footer.vue?vue&type=style&index=0&id=fc4fa4ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc4fa4ce",
  null
  
)

/* custom blocks */
import block0 from "./ehc-white-label-footer.vue?vue&type=custom&index=0&blockType=ehc-white-label-footer&%3AcompanyLabel=companyLabel&%3AcompanyImage=companyImage&%3AcompanyUrl=companyUrl"
if (typeof block0 === 'function') block0(component)

export default component.exports