<template>
  <v-toolbar flat dense class="px-0 mx-0" color="#F4F1DE" max-height="48px">
    <v-toolbar-title>
        <v-img src="@/assets/icons/everhost_v3_logo.svg" contain max-height="50px" width="190px" class="ma-0 pa-0"></v-img>
    </v-toolbar-title>
    <v-divider vertical inset></v-divider>

    <v-spacer/>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >{{message.text}}</span>
      </template>
      <span v-html="message.toolTip"></span>
    </v-tooltip>    
    <v-btn 
      icon
      @click="logout"
      >
        <v-icon>mdi-logout</v-icon>  
    </v-btn>
  </v-toolbar>
</template>

<script>
import mixins from '@/mixins'
import ehcButtonSmall from './ehc-button-small.vue'

export default {
  components: { ehcButtonSmall },
  props: ['message'],
  mixins: [mixins],
  methods: {
    logout: function(){
      this.$store.dispatch("logout")
      this.goToRoute("Home")
    }
  },
  created(){
    this.$store.dispatch('getProperties')
  }
  
}
</script>

<style lang="postcss" scoped>
.divider {
  border-width: 1px !important;
  border-color: white !important;
  height: 100%;
}
</style>