<template>
  <div>

    <ehc-unpublished-overlay opacity=".8" class="text-h5" v-if="!published && !userIsAuthenticated" >
            Oops!  This listing is currently not available.<br/><br/>Please contact your host or listing admin.
    </ehc-unpublished-overlay>

    <div class="ehc-propavatar">
      <v-avatar
        :size="avatarSize"
        class="avatar"
      >
        <v-img
          :src=avatarImg
          alt="Property Name"
        >
      
        </v-img>
      </v-avatar>
      <!-- <v-btn 
        small 
        class="ma-auto ehc-propavatar-edit" 
        fab 
        v-if="userIsAuthenticated" 
        icon 
        @click="imagePopup = !imagePopup">
          <v-icon>mdi-pencil</v-icon>
      </v-btn>   -->
      <div class= "ehc-propavatar-edit" v-if="userIsAuthenticated"   >
        <v-img src="/img/base.svg" max-height="48" max-width="48" >
            <v-img src="/img/camera.fill_black.svg" max-height="30" max-width="30" class="ehc-propavatar-edit-camera" @click="imagePopup = !imagePopup"></v-img>
        </v-img>
      </div>
    </div>
    <ehc-image-upload 
      circle
      v-model="imagePopup"
      title="Upload Property Image" 
      :uploadPath="'/propertyPictures/'+property.propertyId+Date.now()+'500x500'" 
      instructions="Select image to upload and insert"
      :size="{
        width:500,
        height:500
      }"
      @upload="setAvatarURL($event)"
      :persistent=isMobile

    />


    <!-- <ehc-dialog 
      v-model=imagePopup 
      title="Upload Property Image"
      close
      >
      <component-info-image @closeDialog = closeImageDialog :askDescription=false :isAvatarImg=true>
        <template slot="header">
        </template>
        <template slot="instructions">
          <p>Square images will look better.</p>
        </template>
      </component-info-image>
    </ehc-dialog> -->


  </div>


</template>

<script>
import componentInfoImage from './component-info-image.vue'
import ehcImageUpload from '@/components/ehc-image-upload.vue'
import EhcUnpublishedOverlay from './ehc-unpublished-overlay.vue'
import mixins from '@/mixins'
import api from '@/mixins/api.vue'

export default {
  components: { componentInfoImage, ehcImageUpload, EhcUnpublishedOverlay  },
  mixins: [mixins, api],
  data() {
    return {
      propertyNameFontColor: "blue-grey",
      imagePopup: false,
      published: false,
    }
  },
  computed: {
    // isPublished: function(){
    //   if ( !this.property.publishedAt || this.property.deletedAt !== null ) { return false }
    //   return true
    // },  
    property() {
      return this.$store.getters.property
    },
    avatarSize: function() {
      if ( window.outerWidth < 600 ) {
        return 150
      }
      return 125
    },
    avatarImg: function(){
      return this.$store.getters.property.avatarImg
    },
    // published: function(){
    //   return this.$store.getters.property.publishedAt
    // },
    pageImage: function(){
      let homeImage =  this.$store.getters.images.filter( (el) => {
        return el.homeImage === true
      })
      // console.log(homeImage.length)
      if ( homeImage.length ) { return homeImage[0] }
      return this.$store.getters.images[0]
    },
    cssTitleClass: function(){
      return `mb-5 ${this.propertyNameFontColor}--text text--darken-4 text-h5 text-sm-h4 text-md-h3`
    },
    pageTitle: function() {
      return this.$store.getters.property.name
    },
  },
  methods: {
    async setAvatarURL(URL) {
      this.apiUpdateProperty(this.property.propertyId, {avatarImg: URL}).then(()=>{
        this.$store.commit('updateProperty', {avatarImg: URL})
      })
    },
    setPropertyNameFontColor(){
      let backgroundColor = this.$route.query.background || this.property.backgroundColor
      this.propertyNameFontColor = this.backgroundImages.filter( (el) => el.name === backgroundColor )[0].propertyNameColor
    },
    closeImageDialog(){
      this.imagePopup = false
    },
    setPublished: function(){
      if ( this.property.publishedAt === null ) 
      { this.published = false }
      else { this.published = true }
    },
  },
  watch: {
    // property: function(){
    //   this.setPublished()
    // },
  },
  created() {
    this.setPublished()
  },
}
</script>

<style scoped>
  .avatar {border:solid 7px white}
  .v-btn{background-color: rgba(255,255,255,.80);}

  .ehc-propavatar-edit {
    /* center inside parent div with class of ehc-propavatar*/
    position: absolute;
    top: 0px;
    left: 50%;
    z-index: 1;
    transform: translate(30px, 75px);
  }

  .ehc-propavatar-edit-camera {
    /* align center */
    position: absolute;
    top: 45%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    /* make clickable */
    cursor: pointer;
  }
</style>