<script>
import moment from 'moment'

export default {
  data () {
    return {
    }
  },
  methods: {    
    debounce: function(fn, delay = 1000) {
      var timeoutID = null
      return function () {
        clearTimeout(timeoutID)
        var args = arguments
        var that = this
        timeoutID = setTimeout(function () {
          fn.apply(that, args)
        }, delay)
      }
    },    
    goToOffSite: function (link) {
      window.open(link)
    },
    returnBack () {
      this.$router.go(-1)
    },
    returnHome () {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    visitUrl: function (url,target) {
      console.log(target)
      let newUrl = this.fixUrl(url)
      if ( !target ) { target = '_new' }
      window.open(newUrl,target)
    },
    fixUrl: function (url) {
      if (!url.includes('http')) { url = 'http://' + url }
      return url
    },
    formatDate: function (value,format = "dateAndTime") {
      let formatString = 'MMMM Do YYYY, h:mm:ss a'
      if ( format === "dateOnly") { formatString = 'MM/D/YY'}
      return moment(value).format(formatString)
    },
    dateFormat: function(value,format){
      return this.formatDate(value,format)
    },
    isMobileX: function () {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true
      } else {
        return false
      }
    },
    onLogout: function () {
      this.$store.dispatch('logout')
    },
    goTo: function (route) {
      if ( route.includes("http") ) {
        this.goToOffSite(route)
      }
      this.$router.push('/' + route)
    },
    goToRoute: function (name, params, query) {
      if ( name === "Signout" ) { 
        this.$store.dispatch('logout')
        return
        }
      if ( params ) { this.goToRouteParams(name,params) }
      if ( query ) { this.goToRouteParams(name,query) }
      if (this.$route.name == name) { return }
      this.$router.push( {name: name} )
    },
    goToRouteParams: function(name,params){
      // console.log("params: ", params)
      // alert(name)
      this.$router.push( {name: name, params: {params}} )
    },
    goToRouteQuery: function(name,query){
      // console.log("params: ", params)
      // alert(name)
      this.$router.push( {name: name, query: {query}} )
    },
    getImageUrl: function(image){
      if ( !image ) { return "" }
      if ( image.includes("firebasestorage") ) {
        return image
      }
      let thisimage = this.$store.getters.images.filter( (el) => el.fileName === image )
      if ( thisimage.length ) {
        return thisimage[0].fileUrl
      }
      return "https://via.placeholder.com/300x200?text=Need+300x200+Access+image"
    },
    compareAB: function(a,b,field){
        let aC = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field].toString();
        let bC = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field].toString();
        return aC.localeCompare(bC)
    },
    timeFormat: function(string){
      const d1 = new Date(string)
      const d2 = moment(string).format('LT')
      return d2
    },
    dollarFormat: function (number) {
      if (typeof number === 'string') { number = Number(number) }
      if ( !number ) { return "" }    
      let newNumberString = number.toFixed(2)
      return '$' + newNumberString
    },
    prependHttp(url, {https = true} = {}) {
      if ( !url ) { return null }
      if (!url.length) {return null}
      if (typeof url !== 'string') {
          throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
      }

      url = url.trim();

      if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
          return url;
      }

      return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
    },
   },
  computed: {
    cssHomeClass: function() {
      let color = this.property.backgroundColor
      let homeClass = `home ${color} text-center` 
      return homeClass
    },
    userIsAuthenticated: function () {
      // Authenticate if devMode is true
      if ( this.$store.getters.devMode ) { return true }
      // Authenticate if user is admin
      if ( this.$store.getters.isAdmin ) { return true }
      // Authenticate if user is authenticated AND the property.uid is the same as the user.uid
      if ( this.$store.getters.user.data !== null && 
        this.$store.getters.user.data.uid === this.$store.getters.property.uid ) {
        return true
      }
      return false
      },
    userIsAdmin: function() {
      return this.$store.getters.isAdmin
    },
    userIsDev: function() {
      try {
        return this.$store.getters.user.data.uid === "s7ODEJrba6YtcvaisMnEmt4XQgh1"
      }
      catch (error) {
        return false
      }
    }, 
    orgIsPremium: function() {
      try {
        if (this.$store.getters.organization.subType === "Premium") {
          return true
        }
        if ( this.userIsAdmin ) {
          return true
        }
        return false
      } catch (error) {
        return false
      }
    },
    user: function () {
      return this.$store.getters.user
    },
    // loading: {
    //   get() {return this.$store.getters.loading},
    //   set(value) {this.$store.commit('setLoading',value)}
    // }, 
    /**
     * Determine the mobile operating system.
     * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
     *
     * @returns {String}
     */
    operatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true
        case 'sm': return false
        case 'md': return false
        case 'lg': return false
        case 'xl': return false
        default: return false
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
  }
}

</script>